import React, { useContext } from "react"
import get from 'lodash/get'
import { graphql } from "gatsby"

import { getCurrentLangKey } from 'ptz-i18n';
import styled from 'styled-components'

import Layout from "../components/layout"
import HeaderSection from '../components/LandingPagesDark/HeaderSection/HeaderSection'
import SEO from "../components/seo"
import RevealOpacity from '../components/Animation/RevealOpacity'
import LinkedPodcasts from '../components/Superbites/LinkedPodcasts/LinkedPodcasts'
import LinkedArticles from '../components/Article/LinkedArticles/LinkedArticles'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
`

const StoryTelling = props => {

  const url = props.location.pathname;
  const { langs, defaultLangKey } = props.data.site.siteMetadata.languages;
 
  let langKey = getCurrentLangKey(langs, defaultLangKey, url);
  let homeLink = `/${langKey}/`

  const data = get(props, 'data', [])
  const seoData = get(props, 'data.seo.nodes[0].seo', [])
  const pageData = get(props, 'data.pageData.nodes[0]', [])
  const linkedArticles = get(props, 'data.pageData.nodes[0].linkedArticles', [])

  // console.log(LanguageContext)
  // const { slugNl, setSlugNl, slugEn, setSlugEn } = useContext(LanguageContext);

  let seoObject = {
    title: seoData.title,
    description: seoData.description,
    lang: 'en',
    image: seoData.social.facebook.image && seoData.social.facebook.image.url,
    imageTwitter: seoData.social.twitter.image && seoData.social.twitter.image.url,
    robots: seoData.advanced.robots && seoData.advanced.robots
  }

  // slugs for translation
  const slugs = {
    slugNl: props.data.translatedUrl.nodes[0].slug,
    slugEn: props.data.pageData.nodes[0].slug,
  }

  return (
    <Layout form={true} data={props.data.site} location={props.location} slugs={slugs}>
      <SEO {...seoObject} />
      <Container>
        <HeaderSection data={data} url={homeLink} pageData={pageData}/>
      </Container>
      <Container>
        <RevealOpacity>
          <LinkedArticles linkedArticles={linkedArticles} url={homeLink} location={props.location} />
        </RevealOpacity>
      </Container>
    </Layout>
  )
}

export default StoryTelling

export const query = graphql`
{
  site{
    siteMetadata{
      languages {
        defaultLangKey
        langs
      }
    }
  }
  translatedUrl:allCraftTiktokTiktokEntry(filter: {siteId: {eq:1}}){
    nodes{
      slug
    }
  }
  pageData:allCraftTiktokTiktokEntry(filter: {siteId: {eq:2}}){
    nodes{
      title
      pageIntro
      uri
      slug
      linkedArticles {
        ... on craft_article_article_Entry {
          id
          title
          blogintro
          slug
          blogreadtime
          dateCreated
          language
          postDate
          author {
            name
          }
          blogcoverimg {
            ... on craft_superkraft_Asset {
              id
              url
            }
          }
          categoriesServices {
            title
          }
        }
      }
    }
  }
  seo:allCraftTiktokTiktokEntry(filter: {siteId: {eq:2}}){
    nodes{
      title
      seo {
        title
        description
        social {
          facebook {
            image {
              url
            }
          }
          twitter {
            image {
              url
            }
          }
        }
        advanced {
          robots
        }
      }
    }
  }
}
`
